<template>
  <validation-observer ref="formEditPassword">
    <form
      @submit.prevent="save"
    >
      <div class="animated fadeIn">
        <b-card class="px-md-2">
          <ResponseAlert ref="response" />
          <h3>{{ $route.meta.breadcrumb[1].text }}</h3>
          <b-row class="mt-2">
            <b-col
              sm="12"
              md="10"
              lg="9"
            >
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="5">
                  <label
                    class="h5"
                    for="old_password"
                  >Password Lama</label>
                </b-col>
                <b-col sm="7">
                  <validation-provider
                    #default="{ errors }"
                    name="Password Lama"
                    rules="required|password|min:8"
                    vid="Password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="old_password"
                        v-model="models.oldPassword"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="old_password"
                        @keydown.space.prevent
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="5">
                  <label
                    class="h5"
                    for="new_password"
                  >Password Baru</label>
                </b-col>
                <b-col sm="7">
                  <validation-provider
                    #default="{ errors }"
                    name="Password Baru"
                    rules="required|password|min:8"
                    vid="Password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="new_password"
                        v-model="models.newPassword"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="password1FieldType"
                        name="new_password"
                        @keydown.space.prevent
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password1ToggleIcon"
                          @click="togglePassword1Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="5">
                  <label
                    class="h5"
                    for="confirm_password"
                  >Konfirmasi Password Baru</label>
                </b-col>
                <b-col sm="7">
                  <validation-provider
                    #default="{ errors }"
                    name="Password konfirmasi"
                    rules="required|confirmed:Password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="confirm_password"
                        v-model="models.confirmPassword"
                        :type="password2FieldType"
                        class="form-control-merge"
                        :state="errors.length > 0 ? false:null"
                        name="confirm_password"
                        @keydown.space.prevent
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password2ToggleIcon"
                          @click="togglePassword2Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <div class="d-flex justify-content-center justify-content-md-end mt-2 mt-md-0">
                <b-button
                  type="button"
                  variant="light"
                  class="btn-min-width rounded"
                  @click="setModels"
                >
                  Batal
                </b-button>
                <b-button
                  v-if="!isDetailPage"
                  type="submit"
                  variant="primary"
                  class="btn-min-width rounded ml-1"
                >
                  Simpan
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import api from '@/utils/api'

/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BCard, BRow, BCol, BInputGroup, BButton, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle

export default {
  name: 'FormEditPassword',
  metaInfo: {
    title: 'Edit Password',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      models: {
        oldPassword: '',
        newPassword: '',
        confirmationPassword: '',
      },
      // Functions
      required,

      // Toggle Password
      passwordFieldType: 'password',
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    isDetailPage() {
      return this.$route.name === 'detail-admin'
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },

    save() {
      this.$refs.formEditPassword.validate().then(async success => {
        if (success) {
          this.$axios.put(`${api.me}/change-password`, {
            oldPassword: this.models.oldPassword,
            newPassword: this.models.newPassword,
          })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success update password',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$router.push('/profile')
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
  },
}
</script>

<style>

</style>
